import { Helmet } from 'react-helmet-async';
import { Container, Typography, TextField, Button, Box, Skeleton, Grid, Alert, Paper, Autocomplete, CircularProgress, IconButton, createFilterOptions, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '../utils/API';
import { HttpStatusCode } from 'axios';
import FileTable from '../sections/files/FileTable';
import TagComponent from '../utils/tags/TagComponent';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useSnackbar } from '../utils/SnackbarProvider';
import QueryBankManager from '../sections/files/QueryBankManager';
import FileTablePreview from '../sections/files/FileTablePreview';

const ToggleViewButtons = ({ view, onViewChange }) => {
    return (
        <Box mb={1}>
            <ToggleButtonGroup
                value={view}
                exclusive
                onChange={onViewChange}
            >
                <ToggleButton value="list" aria-label="list">
                    <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="module" aria-label="module">
                    <ViewModuleIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>

    );
}

export default function FilesPage() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [view, setView] = useState('list');
    const [searchInitiated, setSearchInitiated] = useState(false);
    const [searchParamsChanged, setSearchParamsChanged] = useState(true); // Track if search params have changed
    const [files, setFiles] = useState({ loading: false, data: [], isTruncated: false });
    const [filters, setFilters] = useState({
        // fileNamePrefix: '',
        from: '',
        to: '',
        uploadFrom: '',
        uploadTo: '',
    });
    const [tags, setTags] = useState([]);
    const [tagNames, setTagNames] = useState({ loading: true, data: [] });

    const allTagsValid = tags.every(tag => tag.key && tag.value.trim() !== '');

    const fetchData = async () => {

        if (!allTagsValid) {
            enqueueSnackbar(t('files.invalidtags'))
            return; // Prevent search from happening
        }

        setFiles({ ...files, loading: true, isTruncated: false });
        setSearchInitiated(true); // Indicate that a search has been initiated
        setSearchParamsChanged(false); // Reset search params changed flag after search

        const tagsObject = tags.reduce((obj, tag) => {
            if (tag.key) obj[tag.key] = tag.value;
            return obj;
        }, {});

        const convertToDateMillis = date => date ? new Date(date).getTime() : null;

        try {
            const payload = {
                // fileNamePrefix: filters.fileNamePrefix,
                from: convertToDateMillis(filters.from),
                to: convertToDateMillis(filters.to),
                uploadFrom: convertToDateMillis(filters.uploadFrom),
                uploadTo: convertToDateMillis(filters.uploadTo),
                tags: tagsObject
            };

            const response = await API.post("/file/listFiles", payload);
            if (response.status === HttpStatusCode.Ok) {
                setFiles({
                    isTruncated: response.data.IsTruncated,
                    data: response.data.FileList,
                    loading: false
                });

            } else {
                setFiles({ ...files, loading: false });
            }
        } catch (error) {
            setFiles({ ...files, loading: false });
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                [name]: value,
            };
            setSearchParamsChanged(true); // Indicate that search params have changed
            return newFilters;
        });
    };

    const loadTagsFromQueryBank = (tags) => {
        setTags(tags);
        setSearchParamsChanged(true);
    }

    const handleAddTagClick = () => {
        setTags([...tags, { key: '', value: '' }]);
    };

    const handleTagChange = (index, field, value) => {
        const newTags = [...tags];
        newTags[index][field] = value;

        // After updating a tag, check if it's now valid to potentially update searchParamsChanged.
        const allTagsHaveValues = newTags.every(tag => tag.key && tag.value.trim() !== '');
        setTags(newTags)
        setSearchParamsChanged(allTagsHaveValues);

    };

    const handleRemoveTag = (index) => {
        const newTags = [...tags.filter((_, i) => i !== index)];
        setTags(newTags);
        setSearchParamsChanged(true); // Indicate that search params have changed
    };

    const handleViewChange = (event, nextView) => {
        setView(nextView);
    };

    const renderTags = () => (
        <Box display="flex" flexWrap="wrap" gap={1} my={2} flexDirection="row-reverse">
            {tags.filter(t => t.key && t.value).map((tag, index) => (
                <TagComponent
                    key={index}
                    tagKey={tag.key}
                    tagValue={tag.value}
                    onDelete={() => handleRemoveTag(index)}
                />
            ))}
        </Box>
    );

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: option => option,
        limit: 5,
    });



    // Fetch available tag names
    useEffect(() => {
        const loadTagNames = async () => {
            setTagNames({ loading: true });
            try {
                const response = await API.get("/file/listTagNames");
                if (response.status === HttpStatusCode.Ok) {
                    const sortedData = response.data.sort((a, b) => b.Count - a.Count);

                    setTagNames({
                        data: sortedData.map(t => t.Name),
                        loading: false
                    });
                } else {
                    setTagNames({ loading: false });
                }
            } catch (error) {
                // setTagNames({ loading: false });
            }
        };

        loadTagNames();
    }, []);


    return (
        <>
            <Helmet>
                <title>{t('files.title')}</title>
            </Helmet>
            <Container maxWidth='xl'>
                <Box>

                    <Typography variant="h4" gutterBottom>
                        {t('files.title')}
                    </Typography>
                    <Grid container justifyContent='space-between' alignItems="flex-end" sx={{ marginTop: 2 }}>
                        <Grid item>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                name="from"
                                label={t('files.from')}
                                value={filters.from}
                                onChange={handleFilterChange}
                                style={{ marginRight: 10, marginBottom: 10 }}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                name="to"
                                label={t('files.to')}
                                value={filters.to}
                                onChange={handleFilterChange}
                                style={{ marginRight: 10, marginBottom: 10 }}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                name="uploadFrom"
                                label={t('files.uploadFrom')}
                                value={filters.uploadFrom}
                                onChange={handleFilterChange}
                                style={{ marginRight: 10 }}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                name="uploadTo"
                                label={t('files.uploadTo')}
                                value={filters.uploadTo}
                                onChange={handleFilterChange}
                                style={{ marginRight: 10 }}
                            />
                        </Grid>
                    </Grid>
                    <Box mb={1}>
                        {!tagNames.loading && tags.map((tag, index) => (
                            <Grid key={index} container spacing={2} alignItems="center" mt={0.1}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        autoSelect
                                        options={tagNames.data}
                                        value={tag.key}
                                        filterOptions={filterOptions}
                                        onChange={(event, newValue) => handleTagChange(index, 'key', newValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Key" variant="outlined" />
                                        )}
                                        disabled={tagNames.loading}
                                        size='small'
                                        freeSolo
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Value"
                                        value={tag.value}
                                        onChange={(e) => handleTagChange(index, 'value', e.target.value)}
                                        variant="outlined"
                                        size="small"

                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => handleRemoveTag(index)} fullWidth variant="outlined" >
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        {
                            tagNames.loading && tags.length > 0 && (
                                <CircularProgress size={24} />
                            )
                        }
                        <QueryBankManager
                            tags={tags}
                            setTags={loadTagsFromQueryBank}
                        />
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleAddTagClick}
                            variant="outlined"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            {t('files.add_tag_filter')}
                        </Button>
                    </Box>
                    <Button
                        onClick={fetchData}
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                        style={{ marginBottom: 10 }}
                        disabled={!searchParamsChanged || !allTagsValid}
                        fullWidth
                    >
                        {t('files.search')}
                    </Button>
                </Box>
                {renderTags()}
                {files.data && files.data.length > 0 && <ToggleViewButtons view={view} onViewChange={handleViewChange} />}
                {!searchInitiated ? (
                    <Paper elevation={3} sx={{ p: 4, mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <SearchIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                        <Typography variant="h6" component="p" sx={{ mt: 2, textAlign: 'center' }}>
                            {t('files.welcome_message')}
                        </Typography>
                        <Typography variant="subtitle1" component="p" sx={{ mt: 1, textAlign: 'center' }}>
                            {t('files.use_filters_message')}
                        </Typography>
                    </Paper>
                ) :
                    files.loading ? (
                        <Box>
                            {Array.from(new Array(5)).map((_, index) => (
                                <Box key={index} display="flex" mb={1}>
                                    <Skeleton variant="text" width="40%" height={30} />
                                    <Skeleton variant="rectangular" width="60%" height={60} style={{ marginLeft: 16 }} />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <>
                            {
                                view === 'list' ? <FileTable
                                    files={files.data}
                                /> : <FileTablePreview files={files.data} />
                            }

                            {files.isTruncated && <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
                                {t('files.warning.truncated_response')}
                            </Alert>}
                        </>
                    )}


            </Container>
        </>
    );
}
